import React from 'react';
import useFormHandler from './hooks/useFormHandler';

const URLForm = ({ setSubmittedLink }) => {
  const {
    realtorLink,
    agentName,
    agentPhone,
    agentEmail,
    handleSubmit,
    handlePhoneChange,
    setRealtorLink,
    setAgentName,
    setAgentEmail,
    loading,
    errorMessage
  } = useFormHandler(setSubmittedLink);

  // Function to validate if the link is from the allowed domains
  const isValidDomain = (url) => {
    const allowedDomains = ['exprealty.com', 'flexmls.com', 'zillow.com'];
    try {
      const { hostname } = new URL(url);
      return allowedDomains.some((domain) => hostname.endsWith(domain));
    } catch {
      return false;
    }
  };

  // Update the url change handler to validate the domain when the input loses focus
  const handleUrlBlur = (e) => {
    const value = e.target.value;
    if (!isValidDomain(value)) {
      alert('The URL must be from one of the following domains: exprealty.com, flexmls.com, zillow.com');
    } else {
      setRealtorLink(value);
    }
  };

  const handleUrlChange = (e) => {
    setRealtorLink(e.target.value);
  };

  const handleValidatedSubmit = (e) => {
    e.preventDefault();
    if (isValidDomain(realtorLink)) {
      handleSubmit(e);
    } else {
      alert('The URL must be from one of the following domains: exprealty.com, flexmls.com, zillow.com');
    }
  };

  return (
    <section className="section form-section">
      <h2>Realtor Property Submission</h2>
      <p>Valid domains: exprealty.com, flexmls.com, zillow.com</p>
      <form onSubmit={handleValidatedSubmit}>
        <div className="form-group">
          <label htmlFor="propertyLink">Property Link:</label>
          <input
            type="url"
            id="propertyLink"
            value={realtorLink}
            onChange={handleUrlChange}
            onBlur={handleUrlBlur}
            placeholder="https://mysharelink.com"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="agentName">Agent Name:</label>
          <input
            type="text"
            id="agentName"
            value={agentName}
            onChange={(e) => setAgentName(e.target.value)}
            placeholder="Enter Agent Name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="agentPhone">Agent Phone:</label>
          <input
            type="tel"
            id="agentPhone"
            value={agentPhone}
            onChange={handlePhoneChange}
            placeholder="(123)456-7890"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="agentEmail">Agent Email:</label>
          <input
            type="email"
            id="agentEmail"
            value={agentEmail}
            onChange={(e) => setAgentEmail(e.target.value)}
            placeholder="Enter Agent Email"
            required
          />
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit Property'}
        </button>
      </form>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </section>
  );
};

export default URLForm;