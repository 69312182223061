import { Helmet } from 'react-helmet';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Listing from './Listing';
import ContactInfo from './ContactInfo';
import RealtyTagLogo from './RealtyTagLogo'; // Import the logo component
import './App.css';

function App() {
  return (
    <>
      <Helmet>
        <title>RealtyTag - QR Code Marketing for Realtors | Boost Property Listings</title>
        <meta name="description" content="Boost your real estate listings with QR codes, capture valuable leads, and offer home-buying coupons to attract potential buyers." />
        <meta name="keywords" content="RealtyTag, QR codes, real estate, coupons, home-buying, lead capture, realtors, property marketing, QR code listings" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="RealtyTag" />
        <meta property="og:title" content="RealtyTag - QR Code Marketing for Realtors" />
        <meta property="og:description" content="Maximize your property listings with RealtyTag QR codes, offering exclusive homebuyer coupons and capturing valuable leads." />
        <meta property="og:image" content="https://yourdomain.com/images/qr-code-preview.png" />
        <meta property="og:url" content="https://www.realtytag.mobi" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="RealtyTag - QR Code Marketing for Realtors" />
        <meta name="twitter:description" content="Enhance your property listings with QR codes and capture potential homebuyer leads easily." />
        <meta name="twitter:image" content="https://yourdomain.com/images/qr-code-preview.png" />
        <meta name='impact-site-verification' value='e771e215-34e7-4954-958c-0f4211ebee31'></meta>
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/contact-info" element={<ContactInfo />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
