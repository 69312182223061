// Example of LogoGrid component
const LogoGrid = ({ logos }) => {
  if (logos.length === 0) {
    return <p>No logos available.</p>;
  }

  return (
    <div className="logo-cards-container">
      {logos.map((logo, index) => (
        <div key={index} className="logo-card">
          <img src={logo.src} alt={logo.name} className="logo-image" />
          
        </div>
      ))}
    </div>
  );
};

export default LogoGrid;
