// Update QRCodeSection.js
import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const rooms = ["Home", "Kitchen", "Living", "Dining", "Family", "Primary", "Patio", "Garage", "Bedroom-1","Bedroom-2","Bedroom-3", "Laundry", "Contact Info","Casita","OnSuite-1","OnSuite-2","Den","Office"];

const QRCodeSection = ({ 
  submittedLink, 
  handleIframeError, 
  iframeError, 
  mainQRCodeRef, 
  roomQRCodeRefs, 
  handleDownloadMainQR, 
  handleDownloadRoomQR 
}) => {

  return (
    <>
      {submittedLink && (
        <section className="iframe-container">
          {!iframeError ? (
            <>
              <iframe
                id="propertyIframe"
                src={submittedLink}
                title="Property Preview"
                width="100%"
                height="500px"
                onError={handleIframeError}
              />
              <div className="qr-code-container">
                <h3>Scan the QR Code:</h3>
                {/* Main QR Code */}
                <div ref={mainQRCodeRef}>
                  <QRCodeCanvas value={submittedLink} size={256} />
                </div>
                <button onClick={handleDownloadMainQR}>Download Main QR Code</button>
              </div>
            </>
          ) : (
            <p>The property link cannot be embedded due to cross-origin restrictions. Please provide a new URL.</p>
          )}
        </section>
      )}

      {submittedLink && (
        <section className="section">
          <h2>QR Codes for Each Room</h2>
          <div className="qr-code-room-container">
            {rooms.map((room) => {
              const roomQrLink = room === "Contact Info" 
                ? `${window.location.origin}/contact-info?submittedLink=${encodeURIComponent(submittedLink)}`  // Pass the submittedLink as a query parameter
                : `${window.location.origin}/listing?url=${submittedLink}&room=${room}`;

              return (
                <div key={room} id={`${room}QR`} className="qr-code-room">
                  <h4>{room} QR Code</h4>
                  {/* Room QR Code */}
                  <div ref={(el) => roomQRCodeRefs.current[room] = el}>
                    <QRCodeCanvas value={roomQrLink} size={256} />
                  </div>
                  <button onClick={() => handleDownloadRoomQR(room)}>Download {room} QR Code</button>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default QRCodeSection;
