import React, { useState, useRef } from 'react';
import './App.css';
import RealtyTagLogo from './RealtyTagLogo'; // Import the logo component
import LeadTable from './LeadTable';
import LogoGrid from './LogoGrid';
import URLForm from './URLForm';
import QRCodeSection from './QRCodeSection';

// Helper function to download QR code as PNG
const downloadQR = (canvas, fileName) => {
  if (!canvas) {
    console.error('Canvas element not found.');
    return;
  }

  // Create a high-resolution canvas for better quality
  const scaleFactor = 4;
  const canvasWidth = canvas.width * scaleFactor;
  const canvasHeight = canvas.height * scaleFactor;

  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = canvasWidth;
  tempCanvas.height = canvasHeight;

  const tempContext = tempCanvas.getContext('2d');
  tempContext.scale(scaleFactor, scaleFactor);
  tempContext.drawImage(canvas, 0, 0);

  const pngUrl = tempCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
  const downloadLink = document.createElement('a');
  downloadLink.href = pngUrl;
  downloadLink.download = `${fileName}.png`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const importAll = (r) => {
  return r.keys().map((item, index) => ({
    src: r(item),
    name: item.replace('./', '').split('.')[0],
  }));
};

const logos = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

const Home = () => {
  const [submittedLink, setSubmittedLink] = useState('');  
  const [iframeError, setIframeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const mainQRCodeRef = useRef(null);
  const roomQRCodeRefs = useRef({});

  const handleIframeError = () => {
    setIframeError(true);
    setErrorMessage('This site does not allow embedding in iframes. Please provide a new URL.');
  };

  const handleDownloadMainQR = () => {
    const mainCanvas = mainQRCodeRef.current?.querySelector('canvas');
    if (mainCanvas) {
      downloadQR(mainCanvas, "Main Listing QR");
    } else {
      console.error('Main QR code canvas not found.');
    }
  };

  const handleDownloadRoomQR = (room) => {
    const roomCanvas = roomQRCodeRefs.current[room]?.querySelector('canvas');
    if (roomCanvas) {
      downloadQR(roomCanvas, `${room} QR`);
    } else {
      console.error(`QR code canvas for ${room} not found.`);
    }
  };

  return (
    <div className="App">
      <header className="header">
      <RealtyTagLogo /> {/* Add the logo here */}
        <h1>Welcome to RealtyTag - Real Estate QR Code & Lead Capture</h1>
        <p>Maximize your property listings with QR codes that capture leads and provide home-buying-related coupons.</p>
      </header>

      <div className="floating-box" onClick={() => document.getElementById('propertyLink').focus()}>
        <p>Get QR Codes to Promote your Listing!</p>
        Realtors try for free
      </div>

      <section className="section">
        <h2>For Real Estate Agents - Capture Leads Easily</h2>
        <LeadTable />
      </section>

      <section className="section">
        <h2>For Homebuyers - Get the Best Deals on Your New Home</h2>
        <LogoGrid logos={logos} />
      </section>

      <URLForm setSubmittedLink={setSubmittedLink} />

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      {submittedLink && (
        <QRCodeSection
          submittedLink={submittedLink}
          handleIframeError={handleIframeError}
          iframeError={iframeError}
          mainQRCodeRef={mainQRCodeRef}
          roomQRCodeRefs={roomQRCodeRefs}
          handleDownloadMainQR={handleDownloadMainQR}
          handleDownloadRoomQR={handleDownloadRoomQR}
        />
      )}

      <footer className="footer">
        <p>&copy; 2024 RealtyTag | Connect your listings, capture leads, provide value!</p>
      </footer>
    </div>
  );
};

export default Home;
