// ContactInfo.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Grid } from '@mui/material';
import './App.css';

const ContactInfo = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const submittedLink = queryParams.get('submittedLink');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    console.log('Submitted Link:', submittedLink); // Debugging to ensure the URL is passed correctly
  }, [submittedLink]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      firstName,
      lastName,
      phone,
      email,
      url: submittedLink,
    };
    try {
      const response = await axios.post('https://w9xkmalp9l.execute-api.us-east-1.amazonaws.com/prod/contact', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Form submitted successfully:', response.data);

      // Set the phone number in a cookie
      document.cookie = `phone=${phone}; path=/; max-age=31536000`; // Expires in 1 year

      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting the form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="App" maxWidth="sm">
      <h1>RealtyTag</h1>
      
      <h2>Contact Information</h2>
      {isSubmitted ? (
        <iframe
          src={submittedLink}
          title="Submitted URL"
          width="100%"
          height="500px"
        />
      ) : (
        <form onSubmit={handleSubmit} className="form-section">
          <Grid container spacing={3}>
            {/* Form Fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="URL"
                variant="outlined"
                value={submittedLink}
                InputProps={{
                  readOnly: true,
                }}
              />
              <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Container>
  );
};

export default ContactInfo;
